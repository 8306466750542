import React, { ReactElement } from "react";
import { Box } from "@chakra-ui/react";
import { getMonstersCollected } from "../../api/monster";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { CardGridMonster } from "../../components/list_homebrew/CardGrid";
import { monsterDataConverter } from "../../components/list_homebrew/DataConverters";
import { COLLECTIONS } from "../../constants/General";
import { useApiClient } from "../../context/ApiClient";
import { useAuthState } from "../../context/AuthState";

export default function Creations(): ReactElement {
  const authState = useAuthState();
  const apiClient = useApiClient();

  return (
    <Box backgroundColor="monster.background" minH="100vh">
      <MyNavbar />
      <CardGridMonster
        title="Saved Monsters"
        showFilters={false}
        apiMethod={(filterValues, paginationOptions) => getMonstersCollected(apiClient.getClient(), authState.id!, paginationOptions)}
        dataConverter={monsterDataConverter}
        contentType="personal"
        collection={COLLECTIONS.Monsters}
      />
    </Box>
  );
}
